<template>
  <div class="passedSuccess-box u-flex-col u-row-between u-text-center" v-cloak>
    <div class="download-app u-flex-col u-row-between">
      <div>
        <h4 class="u-flex u-row-center">
          <img src="../assets/images/logo.png" />
          <span>发出的现金红包</span>
        </h4>
        <h1 class="u-flex u-row-center">
          <span>￥</span>
          <p>8.80</p>
        </h1>
        <h5>领取成功</h5>
        <h5>请尽快完成注册，前往APP提现</h5>
      </div>
      <div class="pr">
        <van-button
          class="download-app-btn"
          type="default"
          @click="handleDownload"
        >
          下载APP完成提现
        </van-button>
        <small class="pa">最高还可获得20万借款额度</small>
      </div>
    </div>

    <!-- 热线电话 -->
    <p class="tel-con u-text-center">
      <van-icon size="0.22rem" color="#E71A0F" name="phone-o" />
      <a href="tel:4001131190">客服热线: 400-1030-615</a>
    </p>
    <!-- 底部风险提示 -->
    <div class="bottom u-text-center">
      综合年化6%-24%，最终结果以审批为准<br />
      贷款有风险，借款需谨慎<br />
      请根据个人能力合理贷款，理性消费<br />
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "TrialSuccessTl2",
  data() {
    return {
      channel: this.$route.query.source, // 渠道标识
      brand: this.$route.query.brand, // 设备型号
      downAppUrl: undefined, // 下载地址
      showPop: false, // 分享蒙层状态
    };
  },
  mounted() {
    let isWeixin = this.isWeixin();
    if (isWeixin) {
      this.showPop = true;
    }

    this.getDownloadUrl();
  },
  methods: {
    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          window.location.href = this.downAppUrl;
        }
      }
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel, true).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.passedSuccess-box {
  height: 100vh;
  background: url("../assets/images/red_envelopes_tl4_downbg.png") no-repeat
    center;
  background-size: 100% 100%;
  padding: 0.82rem 0;

  > .download-app {
    margin: 0 auto;
    width: 5rem;
    height: 5.94rem;
    padding: 0.6rem 0 0.3rem;
    background: url("../assets/images/red_envelopes_downicon.png") no-repeat
      center;
    background-size: 100% 100%;

    h4 {
      font-size: 0.22rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #3c3c34;

      > img {
        width: 0.36rem;
        height: 0.36rem;
        border-radius: 50%;
        margin-right: 0.08rem;
      }
    }

    h1 {
      margin: 0.22rem 0 0.3rem;
      font-size: 0.96rem;
      font-family: AlibabaPuHuiTiB, AlibabaPuHuiTiB-Regular;
      font-weight: 400;
      color: #e1624d;

      > span {
        font-size: 0.48rem;
        margin-top: 0.3rem;
      }
    }

    h5 {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ae8968;
    }

    .download-app-btn {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #3c3c34;
      width: 3.76rem;
      height: 0.84rem;
      background: #fcf2df;
      border-radius: 0.48rem;

      + small {
        top: -0.3rem;
        left: 1.6rem;
        display: block;
        background: #f03d23;
        border: 0.02rem solid #ffffff;
        border-radius: 2rem 2rem 2rem 0;
        font-size: 0.22rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #ffffff;
        padding: 0.02rem 0.16rem;
      }
    }
  }

  .bottom {
    margin: 0.3rem auto;
    font-family: PingFangSC, PingFangSC-Regular;
    font-size: 0.22rem;
    color: #c3c2c6;
    line-height: 0.36rem;
    white-space: pre-line;
  }
}
</style>
