import request from "@/utils/request";

/*
 *@描述: 获取协议内容
 *@参数: data:{
   pageType: 协议
 }
 *@作者: 金涛
 *@日期: 2021-10-25 10:49:14
 */
export function getContent(pageType) {
  return request({
    url: `/content/get`,
    method: "POST",
    headers: {
      loading: false,
    },
    data: {
      pageType,
    },
  });
}

/*
 *@描述: 获取所有地区
 *@参数:  无
 *@作者: 金涛
 *@日期: 2021-10-25 15:24:32
 */
export function getRegions(id) {
  return request({
    url: `/regions/${id}/children`,
    method: "GET",
    headers: {
      loading: false,
    },
  });
}

/*
 *@描述: 获取跳转商店的下载链接
 *@参数: channel: 渠道标识
        是否需要token
 *@作者: 金涛
 *@日期: 2021-10-26 15:38:54
 */
export function getDownloadUrl(channel, needToken = false) {
  return request({
    url: `/channel/download`,
    method: "GET",
    headers: {
      channel,
      needToken,
    },
  });
}

/*
 *@描述: 获取跳转产品列表页面的产品列表数据
 *@参数: data {
    pageNum: 1,
    pageSize: 5,
 }
 *@作者: 金涛
 *@日期: 2022-04-26 20:26:54
 */
export function queryProductList(data) {
  return request({
    url: "/product/api/h5CpaProductPageList",
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

/*
 *@描述: 融树客的产品推荐页面列表点击进行埋点(需要登录)
 *@参数: data {
   productId: 产品ID,
   showPage: 页面点击区域类型
 }
 *@作者: 金涛
 *@日期: 2022-06-08 19:23:40
 */
export function buried(data) {
  return request({
    url: "/product/api/addLoanRecord",
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

/*
 *@描述: 融树客的产品推荐页面列表点击进行埋点(不需要登录)
 *@参数: data {
   productId: 产品ID,
   showPage: 页面点击区域类型
   busType:3 (2-默认传值 3-未登录CPA列表h5页)
 }
 *@作者: 金涛
 *@日期: 2022-10-16 17:23:40
 */
export function notLoginBuried(data) {
  return request({
    url: "/product/api/addLoanRecord",
    method: "POST",
    data,
  });
}

/*
 *@描述: 进件信息的页面，保存进件信息(原异步进件接口/rsa/user/h5/apply)
 更换为(同步接口/rsa/loan/h5ApplyV2)
 逻辑更改为
 1）用户完成进件判断当前用户是否可以被推送
 2）可以被推送：进入引导接听电话页面（按照模版配置的引导接听电话页面显示）
    不能被推送：跳转app下载页面（固定使用调起APP时的下载页模版）
 *@参数: data: {
    channelNo: // 渠道标识
    realName: "", // 姓名
    idCard: "", // 身份证号码
    credit: "", // 芝麻分
    jdIous: "", // 京东分
    assets: "", // 个人资产
    gpsCityName: "", // 省市展示名称
    cityId: "", // 城市ID
    provinceId: "", // 省ID
 }
 *@作者: 金涛
 *@日期: 2022-08-11 11:36:08
 */
export function submitAnEntry(data) {
  return request({
    url: "/rsa/loan/h5ApplyV2",
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

/*
 *@描述: 获取融树客三方签约跳转回调页面H5 获取订单信息
 *@参数: {
  orderNo: 订单编号
  opt: loan=放款、repay=还款
 }
 *@作者: 金涛
 *@日期: 2022-08-09 15:06:23
 */
export function queryOrderInfo(orderNo, opt) {
  return request({
    url: `/loan/getReturnByOrderSn/${orderNo}/${opt}`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/*
 *@描述: 进件流程结果页更换，需要查询进件成功的机构名称以及机构logo
 *@参数: applyId: 进件申请ID
 *@作者: 金涛
 *@日期: 2022-10-23 18:40:45
 */
export function getMechanismInfo(applyId) {
  return request({
    url: `/loan/h5ApplyAuditResult/${applyId}`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/*
 *@描述: 进件流程结果页更换，需要查询进件成功的机构名称以及机构logo
 *@参数: applyId: 进件申请ID
 *@作者: 金涛
 *@日期: 2022-10-23 18:40:45
 */
export function getSemiAutoMechanismInfo(productId) {
  return request({
    url: `/semiAuto/h5ApplyAuditResult/${productId}`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/*
 *@描述: 获取进件结果页面的单个产品详情信息
 *@参数: showPage: 15,H5进件完成页(用于页面底部的产品详情查询),16,投放页完成下载APP(用于页面弹窗的产品详情查询),
 *@作者: 金涛
 *@日期: 2022-10-25 20:49:37
 */
export function getProductInfo(data) {
  return request({
    url: "/product/api/recommend/single",
    method: "post",
    headers: {
      needToken: true,
    },
    data,
  });
}

/*
 *@描述: 获取H5还款计划列表
 *@参数: applyId: 还款计划ID
 *@作者: 金涛
 *@日期: 2022-10-27 15:36:45
 */
export function getApplyData(applyId) {
  return request({
    url: `/loan/repayPlanItems/${applyId}`,
    method: "GET",
    headers: {
      needToken: true,
      loading: false,
      device: "APP",
    },
  });
}

/**
 * 推送进见申请
 * @param {*} data
 * @returns
 */
export function pushApply(data) {
  return request({
    url: "/loan/pushApply",
    method: "post",
    headers: {
      needToken: true,
    },
    data,
  });
}

//获取特定结构的隐私协议
export function getSpecificInstitute(data) {
  return request({
    url: `/rsa/loan/h5/thirdPartyPage`,
    method: "post",
    headers: {
      needToken: true,
    },
    data,
  });
}
